import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedAudioRecorder from './components/EnhancedAudioRecorder';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HTMLContent from './components/HTMLContent';
import MentorReport from './components/MentorReport';
import NavigationSpeedDial from './components/NavigationSpeedDial';
import ImageSlideshow from './components/ImageSlideshow';
import MedilistForm from './components/MedilistForm';
import './darkTheme.css';
import axios from 'axios';

axios.defaults.withCredentials = true;

const GoldilocksBox = ({ title, children }) => (
  <div className="goldilocks-box mb-6">
    <div className="box w-full rounded-lg shadow-md bg-white">
      <h5 className="text-sm font-bold mb-2 bg-blue-royal text-white p-2 rounded-t-lg">{title}</h5>
      <div className="text-left text-base text-black p-4">{children}</div>
    </div>
  </div>
);

const InstructionsModal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-center items-center" onClick={onClose}>
      <div className="bg-black p-6 rounded-lg max-w-2xl max-h-[90vh] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl font-bold mb-4">Instructions</h2>
        <div>{content}</div>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const MessageBubble = ({ speaker, text, personaName }) => (
  <div className={`goldilocks-box mb-6 ${speaker === 'Student' ? 'pl-0 pr-1/4' : 'pl-1/4 pr-0'}`}>
    <div className={`box w-full p-0 rounded-lg shadow-md`}>
      <p className={`text-sm font-bold mb-0 px-4 py-2 w-full rounded-t-lg ${speaker === 'Student'
        ? 'bg-blue-royal text-white'
        : 'bg-[#dc7c86] text-white'
        }`}>
        {speaker === 'Student' ? 'You' : personaName}
      </p>
      <div className="p-4">
        <p className="text-left text-base text-black">{text}</p>
      </div>
    </div>
  </div>
);

const MessageBubbleLive = ({ speaker, text, personaName }) => (
  <div className={`livebox mb-6 ${speaker === 'Student' ? 'pl-0 pr-1/4' : 'pl-1/4 pr-0'}`}>
    <div className={` w-full p-0 rounded-lg shadow-md`}>
      <div className="p-4">
        <h3 className="text-left text-base text-[#ffdec0]">{text}</h3>
      </div>
    </div>
  </div>
);

const ChecklistSection = ({ initialChecklist, currentChecklist }) => (
  <div>
    {currentChecklist && currentChecklist.sections ? (
      currentChecklist.sections.map((section, index) => (
        <div key={index} className="checklist-section mt-4 bg-gray-800 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2 text-white">{section.name}</h4>
          <ul className="list-none">
            {section.items.map((item, itemIndex) => (
              <li key={itemIndex} className="mb-2">
                <h5 className="text-md font-medium text-pink-400">{item.topic}</h5>
                <ul className="list-disc list-inside pl-4">
                  {item.elements.map((element, elementIndex) => (
                    <li key={elementIndex} className="text-white">
                      <strong>{element.term}:</strong> {element.evaluation}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      ))
    ) : initialChecklist && initialChecklist.sections ? (
      initialChecklist.sections.map((section, index) => (
        <div key={index} className="checklist-section mt-4 bg-gray-800 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2 text-white">{section.name}</h4>
          <ul className="list-disc list-inside">
            {section.items.map((item, itemIndex) => (
              <li key={itemIndex} className="text-white">{item.topic}</li>
            ))}
          </ul>
        </div>
      ))
    ) : (
      <p className="text-black">.</p>
    )}
  </div>
);

function PersonaPage() {
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  const { personaType, personaName } = useParams();
  const [selectedPage, setSelectedPage] = useState('instructions');
  const [personaData, setPersonaData] = useState(null);
  const [error, setError] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [emotionalState, setEmotionalState] = useState({ baseEmotion: 'neutral', number: '1' });
  const [interactionCount, setInteractionCount] = useState(0);
  const [conversationId, setConversationId] = useState(null);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [medImages, setMedImages] = useState([]);
  const [showMedilist, setShowMedilist] = useState(false);
  const [initialChecklist, setInitialChecklist] = useState(null);
  const [currentChecklist, setCurrentChecklist] = useState(null);
  const [evaluationHtml, setEvaluationHtml] = useState(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [latestResponse, setLatestResponse] = useState(null);
  const [isLogoPulsing, setIsLogoPulsing] = useState(true);
  const [isConversationEnded, setIsConversationEnded] = useState(false);

  const medilistFormContent = (
    <div>
      <h2 className="text-2xl font-bold mb-4">Medication List Form</h2>
      <MedilistForm personaName={personaName} conversationId={conversationId} />
    </div>
  );

  useEffect(() => {
    setShowInstructionsModal(true);
  }, []);

  const handleCloseInstructionsModal = () => {
    setShowInstructionsModal(false);
    setSelectedPage('context');
  };

  useEffect(() => {
    console.log('Fetching persona data...');
    fetch(`https://www.nump0.com/api/persona/${personaType}/${personaName}`, {
      credentials: 'include'
    })
      .then(response => {
        console.log('Response received:', response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Persona data received:', data);

        if (data.redirect) {
          window.location.href = data.redirect;
        } else {
          setPersonaData(data);
          setConversationId(data.conversation_id);
          setUser(data.user);
          setUserName(data.user_name);
          if (data.conversation_history) {
            setConversationHistory(parseConversationHistory(data.conversation_history.replace(/\[\[\*\*\]\]/g, ' ').trim()));
            setInteractionCount(parseConversationHistory(data.conversation_history).length);
          }
          if (data.med_images) {
            setMedImages(Array.from({ length: data.med_images }, (_, i) => i + 1));
          }
          setShowMedilist(Boolean(data.medilist));

          if (data.checklist) {
            setInitialChecklist(data.checklist);
            setCurrentChecklist(data.checklist);
            console.log('Initial checklist:', data.checklist);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching persona data:', error);
        setError(error.toString());
      });
  }, [personaType, personaName]);

  useEffect(() => {
    if (interactionCount > 1 && !hasInteracted) {
      
      setSelectedPage('live');
      
      setHasInteracted(true);
    }
  }, [interactionCount, hasInteracted, initialChecklist]);

  useEffect(() => {
    console.log('Emotional state changed:', emotionalState);
  }, [emotionalState]);

  const imagesContent = (
    <div>
      <h2 className="text-2xl font-bold mb-4">Medical Images</h2>
      {medImages.length > 0 ? (
        <ImageSlideshow images={medImages} personaName={personaName} />
      ) : (
        <GoldilocksBox title="No Images">
          <p>No medical images available for this persona.</p>
        </GoldilocksBox>
      )}
    </div>
  );

  const parseConversationHistory = (historyString) => {
    return historyString.replace(/\[\[\*\*\]\]/g, '').split('\n').map(line => {
      const [speaker, text] = line.split(': ');
      return { speaker, text: text };
    });
  };

  const generateEvaluationHtml = (checklist) => {
    return `
      <div class="checklist-container">
        ${checklist.sections.map(section => `
          <div class="section">
            <h3 style="color:#535eb4">${section.name}</h3>
            ${section.items.map(item => `
              <div class="item">
                <h4 style="color:pink">${item.topic}</h4>
                <ul class="ul-mentor">
                  ${item.elements.map(element => `
                    <li class="li-mentor">
                      <label>
                        <input type="checkbox" /> 
                        <strong>${element.term}:</strong><span style="color:#535eb4"> ${element.evaluation}</span>
                      </label>
                    </li>
                  `).join('')}
                </ul>
              </div>
            `).join('')}
          </div>
        `).join('')}
      </div>
    `;
  };

  const handleResponseReceived = useCallback((conversation_id, response) => {
    setLatestResponse({ speaker: personaName, text: response });
  }, [personaName]);

  const handleHistoryUpdate = useCallback(async (conversation_id, student_text, persona_text) => {
    const newHistory = [
      ...conversationHistory,
      { speaker: 'Student', text: student_text },
      { speaker: personaName, text: persona_text }
    ];
    setConversationHistory(newHistory);
    setInteractionCount(prevCount => prevCount + 2);

    if (currentChecklist && !isEvaluating) {
      setIsEvaluating(true);
      const transcript = newHistory.map(msg => `${msg.speaker}: ${msg.text}`).join('\n');
      const formData = new FormData();
      formData.append('transcript', transcript);
      formData.append('checklist', JSON.stringify(currentChecklist));
      formData.append('conversation_id', conversation_id);

      try {
        const response = await fetch('https://nump0.com/persona/eval_checklist/', {
          method: 'POST',
          body: formData,
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Parsed evaluation result:', result);

        if (result.title && Array.isArray(result.sections)) {
          setCurrentChecklist(result);
          const htmlContent = generateEvaluationHtml(result);
          setEvaluationHtml(htmlContent);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Invalid response format from server');
        }
      } catch (error) {
        console.error('Error evaluating checklist:', error);
        setEvaluationHtml(`<p>Error evaluating checklist: ${error.message}</p>`);
      } finally {
        setIsEvaluating(false);
      }
    }
  }, [conversationHistory, personaName, setConversationHistory, setInteractionCount, currentChecklist]);

  const handleImageError = (e) => {
    e.target.onerror = null;
    const filename = e.target.src.split('/').pop();
    const [emotionalState, number] = filename.split(/(\d+)\.png$/);

    const newNumber = number === '1' ? '2' : '1';
    const newFilename = `${emotionalState}${newNumber}.png`;

    e.target.src = `/images/personas/${personaName.split('_')[0].toLowerCase()}/${newFilename}`;

    e.target.onerror = () => {
      e.target.src = `/images/personas/${personaName.split('_')[0].toLowerCase()}/neutral.png`;
    };
  };

  const handleEndConversation = useCallback(() => {
    setIsConversationEnded(true);
    setSelectedPage('mentor');
    generateMentorReport();
  }, []);

  const generateMentorReport = async () => {
    try {
      const response = await fetch('https://www.nump0.com/generate_mentor_report', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversationId,
          personaType,
          personaName,
          user,
          userName,
          conversationHistory: conversationHistory.map(msg => `${msg.speaker}: ${msg.text}`).join('\n'),
        }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to generate mentor report');
      }

      const reportData = await response.json();
      // Handle the report data as neede
    } catch (error) {
      console.error('Error generating mentor report:', error);
      // Handle the error, perhaps by showing an error message to the user
    }
  };

  const handleLogoClick = () => {
    setIsLogoPulsing(false);
  };

  const handleEmotionalStateChange = (newEmotionalState) => {
    console.log('Updating emotional state:', newEmotionalState);

    if (typeof newEmotionalState === 'string') {
      if (!/\d$/.test(newEmotionalState)) {
        setEmotionalState(newEmotionalState);
      } else {
        const baseEmotion = newEmotionalState.replace(/[0-9]/g, '');
        const number = newEmotionalState.slice(-1);
        setEmotionalState({
          baseEmotion,
          number
        });
      }
    } else {
      setEmotionalState(newEmotionalState);
    }
  };

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!personaData) {
    return <p>Loading...</p>;
  }

  const { persona, conversation_structure, information, medilist, med_images, referral, voice, instructions } = personaData;

  const conversation_structure_text = conversation_structure ? conversation_structure : '';

  const liveContent = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh' }}>
      {latestResponse ? (
        <MessageBubbleLive
          speaker={latestResponse.speaker}
          text={latestResponse.text.replace(/\[\[\*\*\]\]/g, ' ')}
          personaName={personaName}
        />
      ) : (
        <p>Waiting for conversation to start...</p>
      )}
    </div>
  );

  const instructionsContent = (
    <div>
      {personaData.instructions ? (
        <HTMLContent content={personaData.instructions} />
      ) : (
        <GoldilocksBox title="No Data">
          <p>No instructions data available.</p>
        </GoldilocksBox>
      )}
    </div>
  );

  const contextContent = (
    <div>
      {personaData.referral ? (
        <HTMLContent content={personaData.referral} />
      ) : (
        <GoldilocksBox title="No Data">
          <p>No referral data available.</p>
        </GoldilocksBox>
      )}
    </div>
  );

  const historyContent = (
    <div className="conversation-history max-h-full overflow-y-auto p-4 rounded-lg">
      {conversationHistory.map((message, index) => {
        console.log("Speaker before split:", message.speaker);
        const speaker = message.speaker.split('_')[0];
        console.log("Speaker after split:", speaker);

        return (
          <MessageBubble
            key={index}
            speaker={message.speaker}
            text={message.text}
            personaName={speaker}
          />
        );
      })}
    </div>
  );

  const resetContent = <p>Reset options go here...</p>;

  const mentorReportContent = (
    <MentorReport
      conversationId={conversationId}
      personaType={personaType}
      personaName={personaName}
      user={user}
      userName={userName}
      conversationHistory={conversationHistory.map(msg => `${msg.speaker}: ${msg.text}`).join('\n')}
    />
  );

  const evaluationContent = (
    <div>
      <h2 className="text-2xl font-bold mb-4">Checklist Evaluation</h2>
      {isEvaluating ? (
        <p>Evaluating checklist...</p>
      ) : evaluationHtml ? (
        <HTMLContent content={evaluationHtml} />
      ) : (
        <p>No evaluation available yet.</p>
      )}
    </div>
  );

  let selectedContent;
  switch (selectedPage) {
    case 'evaluation':
      selectedContent = evaluationContent;
      break;
    case 'medilist':
      selectedContent = medilistFormContent;
      break;
    case 'images':
      selectedContent = imagesContent;
      break;
    case 'context':
      selectedContent = contextContent;
      break;
    case 'history':
      selectedContent = historyContent;
      break;
    case 'reset':
      selectedContent = resetContent;
      break;
    case 'mentor':
      selectedContent = mentorReportContent;
      break;
    case 'instructions':
      selectedContent = instructionsContent;
      break;
    case 'live':
    default:
      selectedContent = liveContent;
      break;
    case 'Dashboard':
      window.location.href = 'https://nump0.com/dashboard';
      return null;
  }
  console.log('emotionalState:', emotionalState);

  console.log("Passing time_limit to EnhancedAudioRecorder:", personaData.time_limit);

  return (
    <div className="App background-tile">
      <InstructionsModal
        isOpen={showInstructionsModal}
        onClose={handleCloseInstructionsModal}
        content={instructionsContent}
      />

      <main className="flex flex-col md:flex-row transition-all duration-300">
        <div className="w-full md:w-1/3 mb-4 md:mb-0">
          <div className="persona-container flex flex-col items-center">
            <div className="controls-container flex flex-col items-center mb-4">
              <div className="audio-recorder-container">
                <EnhancedAudioRecorder
                  personaName={personaName}
                  conversation_history={conversationHistory.map(msg => `${msg.speaker}: ${msg.text}`).join('\n')}
                  personaType={personaType}
                  time_limit={personaData.conversation_structure.time_limit}
                  conversation_id={conversationId}
                  voice={voice}
                  onHistoryUpdate={handleHistoryUpdate}
                  onResponseReceived={handleResponseReceived}
                  user={user}
                  onEmotionalStateChange={handleEmotionalStateChange}
                  onEndConversation={handleEndConversation}
                />
              </div>
            </div>
            <div className="persona-image-container relative w-full max-w-md">
              <div
                className="bg-image absolute inset-0 bg-cover bg-center rounded-2xl"
                style={{ backgroundImage: 'url("/images/images/bg1.png")', opacity: '0.5'  }}
              ></div>
              <div
                className="absolute inset-0 rounded-2xl"
                style={{ background: 'radial-gradient(circle, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 40%)' }}
              ></div>
              <img
                src={`/images/personas/${personaName.toLowerCase()}/${emotionalState.baseEmotion}${emotionalState.number}.png`}
                onError={handleImageError}
                alt="Persona"
                className="w-full h-auto relative rounded-2xl"
              />
            </div>
            <div className="md:hidden mt-4 w-full">
              {liveContent}
            </div>
            <ChecklistSection initialChecklist={initialChecklist} currentChecklist={currentChecklist} />
          </div>
        </div>

        <div className="content-container w-full md:w-2/3 p-4 transition-all duration-300">
          <NavigationSpeedDial
            onSelect={setSelectedPage}
            showMedilist={showMedilist}
            showEvaluation={Boolean(evaluationHtml || initialChecklist)}
            med_images={personaData.med_images}
          />
          <div className="mb-4">
            <a href="https://nump0.com/dashboard" onClick={handleLogoClick}>
              <img
                src="/images/header/logo.png"
                alt="ATLAS"
                className={`logo ${isLogoPulsing ? 'pulsing' : ''}`}
                style={{ height: '160px' }}
              />
            </a>
          </div>
          <div className={`${selectedPage === 'live' ? 'hidden md:block' : ''}`}>
            {selectedContent}
          </div>
        </div>
      </main>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/persona/:personaType/:personaName" element={<PersonaPage />} />
        <Route path="/" element={<ExternalRedirect to="https://nump0.com/dashboard" />} />
      </Routes>
    </Router>
  );
}

const ExternalRedirect = ({ to }) => {
  React.useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

export default App;